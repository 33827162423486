var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('div',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.offBizDetail.id ? ("Phiếu linh kiện chờ xử lý #" + (_vm.offBizDetail.code)) : "Thêm mới phiếu linh kiện chờ xử lý")+" ")]),(
        _vm.offBizDetail.selected_action === _vm.selectedActions.approval_for_sale
      )?_c('v-chip',{staticClass:"white font-weight-bold ml-3",attrs:{"color":"green","outlined":""}},[_vm._v(" Đã duyệt bán ")]):_vm._e(),_c('v-spacer'),(_vm.offBizDetail.id && !_vm.hasCancelOrSold())?_c('v-btn',{staticClass:"red lighten-4 red--text text-accent-2 rounded-lg mr-3",attrs:{"depressed":"","loading":_vm.statusRequest.value === 'loading-addOffBiz'},on:{"click":function($event){_vm.statusRequest.value === 'loading-addOffBiz'
          ? null
          : _vm.openModalCancelingOffBiz()}}},[_vm._v(" Hủy ")]):_vm._e(),(!_vm.offBizDetail.id)?_c('v-btn',{staticClass:"rounded-lg",attrs:{"color":"primary","depressed":"","loading":false},on:{"click":_vm.createOffBiz}},[_vm._v(" Thêm ")]):_vm._e(),(
        _vm.offBizDetail.id &&
          _vm.offBizDetail.step === _vm.stepList.length &&
          _vm.hasTranferHandling() &&
          !_vm.hasCancelOrSold()
      )?_c('v-btn',{staticClass:"primary rounded-lg",attrs:{"dark":"","depressed":"","loading":false},on:{"click":function($event){return _vm.tranferHandling()}}},[_vm._v(" Chuyển xử lý ")]):_vm._e(),(
        _vm.offBizDetail.id &&
          _vm.offBizDetail.step === _vm.stepList.length &&
          _vm.hasTranferHandling() &&
          !_vm.hasCancelOrSold()
      )?_c('v-btn',{staticClass:"primary rounded-lg ml-3",attrs:{"dark":"","depressed":"","loading":false},on:{"click":function($event){return _vm.updateOffBiz(_vm.selectedActions.approval_for_sale)}}},[_vm._v(" Duyệt bán ")]):_vm._e()],1),(_vm.offBizDetail.selected_action === _vm.selectedActions.cancel)?_c('v-card',{staticClass:"mt-5",attrs:{"flat":""}},[_c('div',{staticClass:"amber lighten-5 text-body-1 px-3 py-2"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Lý do hủy:")]),_vm._v(" "+_vm._s(_vm.offBizDetail.ly_do_huy)+" ")])]):_vm._e(),_c('div',{staticClass:"main-detail--content mt-2"},[_c('card-processing',{staticClass:"card-processing mt-5",attrs:{"step-list":_vm.stepList,"store-name":'WAITING_PROCESSING_NOTE'}}),_c('tranfer-handling-modal',{attrs:{"storeName":'WAITING_PROCESSING_NOTE'}}),(_vm.offBizDetail.id)?_c('off-biz-cancel-modal',{attrs:{"homeRoute":'not-sold-warehouse_waiting-processing-notes',"storeName":'WAITING_PROCESSING_NOTE',"type":'phiếu linh kiện',"code":_vm.offBizDetail.id.toString(),"id":_vm.offBizDetail.id,"item":_vm.offBizDetail}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }