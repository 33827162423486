<template>
  <div style="height: 100%">
    <!-- Start: Top -->
    <div class="d-flex flex-row align-center">
      <div class="text-h5 font-weight-bold">
        {{
          offBizDetail.id
            ? `Phiếu linh kiện chờ xử lý #${offBizDetail.code}`
            : "Thêm mới phiếu linh kiện chờ xử lý"
        }}
      </div>
      <v-chip
        v-if="
          offBizDetail.selected_action === selectedActions.approval_for_sale
        "
        class="white font-weight-bold ml-3"
        color="green"
        outlined
      >
        Đã duyệt bán
      </v-chip>
      <v-spacer></v-spacer>
      <v-btn
        v-if="offBizDetail.id && !hasCancelOrSold()"
        class="red lighten-4 red--text text-accent-2 rounded-lg mr-3"
        depressed
        :loading="statusRequest.value === 'loading-addOffBiz'"
        @click="
          statusRequest.value === 'loading-addOffBiz'
            ? null
            : openModalCancelingOffBiz()
        "
      >
        Hủy
      </v-btn>
      <!--      <v-btn-->
      <!--        v-if="offBizDetail.id && !hasCancelOrSold()"-->
      <!--        class="rounded-lg"-->
      <!--        color="primary"-->
      <!--        depressed-->
      <!--        :loading="false"-->
      <!--        @click="updateOffBiz(selectedActions.save)"-->
      <!--      >-->
      <!--        Lưu-->
      <!--      </v-btn>-->
      <v-btn
        v-if="!offBizDetail.id"
        class="rounded-lg"
        color="primary"
        depressed
        :loading="false"
        @click="createOffBiz"
      >
        Thêm
      </v-btn>
      <v-btn
        class="primary rounded-lg"
        dark
        depressed
        :loading="false"
        v-if="
          offBizDetail.id &&
            offBizDetail.step === stepList.length &&
            hasTranferHandling() &&
            !hasCancelOrSold()
        "
        @click="tranferHandling()"
      >
        Chuyển xử lý
      </v-btn>
      <v-btn
        class="primary rounded-lg ml-3"
        dark
        depressed
        v-if="
          offBizDetail.id &&
            offBizDetail.step === stepList.length &&
            hasTranferHandling() &&
            !hasCancelOrSold()
        "
        :loading="false"
        @click="updateOffBiz(selectedActions.approval_for_sale)"
      >
        Duyệt bán
      </v-btn>
    </div>
    <!-- End: Top -->
    <!-- Start: Cancel reason -->
    <v-card
      v-if="offBizDetail.selected_action === selectedActions.cancel"
      class="mt-5"
      flat
    >
      <div class="amber lighten-5 text-body-1 px-3 py-2">
        <span class="font-weight-bold">Lý do hủy:</span>
        {{ offBizDetail.ly_do_huy }}
      </div>
    </v-card>
    <!-- End: Cancel reason -->
    <!-- Start: Main content -->
    <div class="main-detail--content mt-2">
      <!-- Start: Processing -->
      <card-processing
        class="card-processing mt-5"
        :step-list="stepList"
        :store-name="'WAITING_PROCESSING_NOTE'"
      ></card-processing>
      <tranfer-handling-modal :storeName="'WAITING_PROCESSING_NOTE'">
      </tranfer-handling-modal>
      <off-biz-cancel-modal
        v-if="offBizDetail.id"
        :homeRoute="'not-sold-warehouse_waiting-processing-notes'"
        :storeName="'WAITING_PROCESSING_NOTE'"
        :type="'phiếu linh kiện'"
        :code="offBizDetail.id.toString()"
        :id="offBizDetail.id"
        :item="offBizDetail"
      ></off-biz-cancel-modal>
      <!-- End: Processing -->
    </div>
    <!-- End: Main content -->
  </div>
</template>

<script>
import CardProcessing from "@/modules/NotSoldWarehouse/components/CardProcessing";
import TranferHandlingModal from "@/modules/NotSoldWarehouse/components/modal/tranfer-handling-modal.vue";
import OffBizCancelModal from "@/modules/NotSoldWarehouse/components/modal/off-biz-cancel-modal.vue";
import {
  SELECTED_ACTIONS,
  SERIAL_STATUS
} from "../../../../constants/constants";
export default {
  components: {
    CardProcessing,
    TranferHandlingModal,
    OffBizCancelModal
  },
  data() {
    return {
      stepList: [
        { id: 1, name: "Chờ tiếp nhận" },
        { id: 2, name: "Đã tiếp nhận" },
        { id: 3, name: "Đã xử lý" }
      ],
      selectedActions: SELECTED_ACTIONS
    };
  },
  computed: {
    offBizDetail() {
      return this.$store.getters["WAITING_PROCESSING_NOTE/offBizDetail"];
    },
    statusRequest() {
      return this.$store.getters["WAITING_PROCESSING_NOTE/statusRequest"];
    }
  },

  beforeDestroy() {
    this.$store.dispatch("WAITING_PROCESSING_NOTE/resetOffBizDetail");
  },
  methods: {
    openModalCancelingOffBiz() {
      this.$modal.show({
        name: "cancel-offbiz-modal",
        payload: {
          item: this.offBizDetail
        }
      });
    },

    async createOffBiz() {
      // Request create
      await this.$store.dispatch(
        "WAITING_PROCESSING_NOTE/addOffBiz",
        this.offBizDetail
      );
      // Alert and replace router
      if (this.statusRequest.value === "success-addOffBiz") {
        // Alert
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thêm phiếu bảo hành thành công"
          }
        });
        // Replace router
        await this.$router.push({
          name: "not-sold-warehouse_waiting-processing-notes"
        });
      }
    },

    hasCancelOrSold() {
      const { selected_action } = this.offBizDetail;
      if (
        selected_action === SELECTED_ACTIONS.cancel ||
        selected_action === SELECTED_ACTIONS.approval_for_sale
      ) {
        return true;
      }
      return false;
    },

    hasTranferHandling() {
      const { serial } = this.offBizDetail;
      if (serial && serial.status === SERIAL_STATUS.WAITING_PROCESSING_NOTE) {
        return true;
      }
      return false;
    },

    async tranferHandling() {
      this.$modal.show({
        name: "tranfer-handling-modal"
      });
    },

    async updateOffBiz(selected_action) {
      // Request create
      const { step } = this.offBizDetail;
      if (
        (selected_action === SELECTED_ACTIONS.tranfer ||
          selected_action === SELECTED_ACTIONS.approval_for_sale) &&
        step < this.stepList.length
      ) {
        return;
      }
      await this.$store.dispatch("WAITING_PROCESSING_NOTE/updateOffBiz", {
        ...this.offBizDetail,
        selected_action
      });
      // Alert
      if (this.statusRequest.value === "success-updateOffBiz") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã lưu cập nhật"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.main-detail--content {
  max-height: calc(100% - 36px - 20px);
  overflow-y: auto;
}
</style>
